<!--
 * @Descripttion: 套餐管理
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:43:10
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-04-29 08:35:16
-->
<template>
    <div class="serviceManagement">
        <div class="sm-title">
            <div class="mt-title">套餐管理</div>
            <div class="mt-add">
                <div class="add" @click="clickAddBtn">创建套餐</div>
            </div>
        </div>
        <div class="sm-content">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="套餐名称"></el-table-column>
                <el-table-column prop="team_num" label="可添加团队用户人数"></el-table-column>
                <el-table-column prop="price" label="套餐价格"></el-table-column>
                <el-table-column  width="140">
                    <template slot-scope="scope">
                        <div class="btn-wrap">
                            <div class="btn" @click="clickEdit(scope.row)">编辑</div>
                            <div class="btn" @click="clickDel(scope.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加 -->
        <el-dialog
            :visible.sync="isShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px">
            <div class="class-wrap">
                <div class="cw-title">{{isShowMD?'编辑套餐':'创建套餐'}}</div>
                <div class="cw-list">
                    <div class="item">
                        <el-input v-model="info.name" placeholder="请输入开通套餐名称"></el-input>
                    </div>
                    <div class="item">
                        <el-input v-model="info.team_num" placeholder="请输入可添加团队用户人数"></el-input>
                    </div>
                    <div class="item">
                        <el-input v-model="info.price" placeholder="请输入套餐价格"></el-input>
                    </div>
                </div>
                <div class="cw-btn" @click="clickAddMain">确定</div>
            </div>
        </el-dialog>
        <!-- 提示 -->
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">确定在套餐列表中删除【{{delName}}】?</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="isDel = false">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'packageManagement',
            isShow: false,
            isShowMD: false,
            isDel: false,
            delName: '',
            serviceName: '',
            servicePhone: '',
            headers: {},
            imageUrl: '',
            tableData: [],
            info: {
                name: '',
                team_num: '',
                price	: '',
            },
            eidtId: ''
        }
    },
    mounted () {
        let token = localStorage.getItem("token");
        var authorization = 'Bearer ' + token
        let headers = {
            'Authorization': authorization
        }
        this.headers = headers;
        this.getServiceList()
    },
    methods: {
        // 获取客服
        getServiceList(){
            let params = {
                page_num: 1,
                page_size: 100,
            }
            api.get('/service/system/get_activate_package_list', params, (res) =>  {
                this.tableData = res.data.list
            });
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            this.info.wechat_qr_code = 'https://ukoo.oss-cn-beijing.aliyuncs.com/'+ res.data.url;
        },
        // 编辑
        clickEdit(row){
            this.isShow = true
            this.isShowMD = true;
            this.info.name = row.name;
            this.info.team_num = row.team_num;
            this.info.price = row.price;
            this.eidtId = row.id
        },
        // 删除
        clickDel(row){
            this.eidtId = row.id;
            this.delName = row.name;
            this.isDel = true
        },
        // 确定删除
        clickConfirm(){
            let params = {
                id: this.eidtId,
            }
            api.post('/service/system/activate_package_del', params, (res) =>  {
                this.$message.success('已删除')
                this.isDel = false
                this.getServiceList()
            });
        },
        // 确定创建客服人员
        clickAddMain(){
            if(this.info.name == ''){
                this.$message.error('请输入开通套餐名称')
                return
            }
            if(this.info.team_num == ''){
                this.$message.error('请输入可添加团队用户人数')
                return
            }
            if(this.info.price == ''){
                this.$message.error('请输入套餐价格')
                return
            }
            if(this.isShowMD){
                // 编辑
                let params = {
                    id: this.eidtId,
                    name: this.info.name,
                    team_num: this.info.team_num,
                    price: this.info.price,
                }
                api.post('/service/system/activate_package_edit', params, (res) =>  {
                    this.$message.success('已编辑')
                    this.isShow = false
                    this.getServiceList()
                });
            }else{
                // 添加
                let params = {
                    name: this.info.name,
                    team_num: this.info.team_num,
                    price: this.info.price,
                }
                api.post('/service/system/activate_package_add', params, (res) =>  {
                    this.$message.success('已添加')
                    this.isShow = false
                    this.getServiceList()
                });
            }
        },
        // 创建客服人员
        clickAddBtn(){
            this.isShow = true
            this.isShowMD = false
            let info = {
                name: '',
                phone: '',
                spare_phone: '',
                wechat_qr_code: '',
            }
            this.info = info;
            this.imageUrl = ''
        },
    },
}
</script>

<style lang='scss'>
.serviceManagement{
    .sm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .add{
                width: 130px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                font-size: 14px;
                color: #0068ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .sm-content{
        margin: 20px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        .code{
            text-align: center;
            width: 150px;
            padding: 5px 20px;
            border: 1px solid #ddd;
            background: #e6effd;
            border-radius: 9px;
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                padding: 0 15px;
                cursor: pointer;
                font-size: 14px;
                color: #0068ff;
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .cw-list{
            padding: 20px;
            .item{
                margin-bottom: 15px;
                .el-select{
                    width: 100%;
                }
                .tip{
                    font-size: 14px;
                    color: #101010;
                    padding-bottom: 15px;
                }
                .avatar{
                    width: 100px;
                    height: 100px;
                    vertical-align: bottom;
                }
                .el-icon-plus{
                    font-size: 60px;
                    color: #c9c9c9;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #ececec;
                }
            }
        }
        .cw-btn{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-size: 15px;
            color: #fff;
            background: #0068ff;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
</style>
